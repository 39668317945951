import { make } from "vuex-pathify"
import { api } from "@/api"
import { dummyImages } from "./teacher"
import { format, parse } from "fecha"
import { transformDataKeys, transformToFormDataSerialize } from "@/utils/profileUtils"

export function transformHarmonogram(timetable) {
  return timetable
    ? timetable.reduce((acc, day) => {
        acc[day.date] = day.slots
        return acc
      }, {})
    : {}
}

const flagMissedSlots = (timetable, limit) => {
  return timetable
    .map((day) => {
      const date = parse(day.date, "YY-MM-DD")
      const slots = day.slots.map((slot) => {
        const userDate = new Date()
        const slotHour = slot.time.split(":")[0]
        const slotMinutes = slot.time.split(":")[1]
        const slotDate = date.setHours(slotHour, slotMinutes, 0, 0)
        const missed = +slotDate - +userDate < limit * 60 * 60 * 1000 // user should see slots 12h beyond his own time.
        return { ...slot, missed }
      })
      return { ...day, slots }
    })
    .filter((day) => day.slots.length)
}
const state = () => ({
  lesson: {},
  voiceSampleProvided: false,
  scheduledLessons: [],
  closedLessons: [],
  groupLessons: [],
  selected: {},
  teacherId: null,
  harmonogram: {},
  dateFrom: null,
  dateTo: null,
  // limit of lessons to be selected in one time
  limit: 2,
  gracePeriod: 12,
  scheduledLessonsCount: 0,
  tooltipState: "",
})
// make all mutations
const mutations = make.mutations(state)

export default {
  namespaced: true,

  state,
  mutations,

  actions: {
    async setScheduledLessons({ commit }) {
      try {
        const response = await api.getScheduledLessons()
        const { lessons, voice_sample_provided, scheduled_lessons_limit_per_student } =
          response.data
        commit("SET_LIMIT", scheduled_lessons_limit_per_student)
        commit("SET_SCHEDULED_LESSONS", lessons)
        commit("SET_VOICE_SAMPLE_PROVIDED", voice_sample_provided)
      } catch (e) {
        console.error(e)
      }
    },
    async setDummyScheduledLessons({ commit }) {
      try {
        let { data } = await api.getDummyScheduledLessons()

        data.forEach((item) => {
          const image = dummyImages.find((url) => {
            return url.toLowerCase().includes(item.teacher.last_name.toLowerCase())
          })
          item.teacher.image_url = image
        })

        commit("SET_SCHEDULED_LESSONS", data)

        return Promise.resolve()
      } catch (e) {
        console.error(e)
      }
    },
    async setClosedLessons({ commit }) {
      try {
        const response = await api.getClosedLessons()
        commit("SET_CLOSED_LESSONS", response.data)
      } catch (e) {
        console.error(e)
      }
    },
    async getGroupLessons({ commit }) {
      try {
        const response = await api.getGroupLessons()
        commit("SET_GROUP_LESSONS", response.data)
      } catch (e) {
        console.error(e)
      }
    },
    async setSchedule({ commit, state, dispatch }, teacherId) {
      const response = await api.getTeacherSchedule(teacherId, state.dateFrom, state.dateTo)

      if (!response) return {}
      const {
        timetable,
        scheduled_lessons_count: scheduledLessonsCount,
        lesson_earliness_condition: gracePeriod,
      } = response

      commit("SET_GRACE_PERIOD", gracePeriod)

      let scheduleTimetable = timetable
      // if student's scheduled_lessons_count is more than 0,
      // but we don't have yet scheduled lessons in state fetch them
      if (scheduledLessonsCount && !state.scheduledLessons.length) {
        await dispatch("setScheduledLessons", state.teacherId)
      }
      // if we have scheduled lessons in state -> compare their date and time
      // to timetable timeslots to prevent setting two lesson at the same time
      if (state.scheduledLessons.length) {
        //convert scheduledLessons to object with date and time as keys
        const scheduledDays = state.scheduledLessons.map((item) => {
          const date = item.scheduled_at
          return {
            date: format(parse(date, "YY-MM-DD HH:mm"), "YYYY-MM-DD"),
            time: format(parse(date, "YY-MM-DD HH:mm"), "HH:mm"),
          }
        })

        scheduledDays.forEach((item) => {
          // firstly get index of object in timetable with same date as our scheduled lesson
          const dateIndex = scheduleTimetable.findIndex((object) => object.date === item.date)
          if (dateIndex < 0) return false
          // secondly get index of nested slot with the same time as scheduled lesson
          const slotIndex = scheduleTimetable[dateIndex].slots.findIndex(
            (slot) => slot?.time === item?.time,
          )
          // finally add to slot new key-value pair "scheduled:true" value
          // it will be used in component to display proper information and disable pickSlot() function
          if (dateIndex >= 0 && slotIndex >= 0) {
            scheduleTimetable[dateIndex].slots[slotIndex].scheduled = true
          }
        })
      }
      // flag slots that are earlier than grace period e.g.12h from now
      scheduleTimetable = flagMissedSlots(scheduleTimetable, gracePeriod)
      // finally set teacher's harmonogram with overwriten timetable
      // <- if there were any scheduled lessons at the same time as harmonogram's slots
      // if not - timetable did not change
      const harmonogram = transformHarmonogram(scheduleTimetable)
      commit("SET_HARMONOGRAM", harmonogram)
      commit("SET_SCHEDULED_LESSONS_COUNT", scheduledLessonsCount)
    },
    selectLesson({ commit, dispatch, state }, lesson) {
      const { id, forceDelete } = lesson
      const selected = Object.assign({}, state.selected)
      if (selected[id]) {
        delete selected[id]
      } else if (!forceDelete) {
        selected[id] = lesson
      }
      commit("SET_SELECTED", { ...selected })
      dispatch("setSchedule", state.teacherId)
    },

    async setLessons({ commit, dispatch, state }, { language_id, level_id }) {
      const time_slot_ids = Object.values(state.selected).map((slot) => slot.id)
      await api.createLesson({
        teacher_id: state.teacherId,
        time_slot_ids,
        language_id,
        level_id,
      })
      commit("SET_SELECTED", {})
      dispatch("setSchedule", state.teacherId)
    },
    async cancelLesson({ dispatch }, credentials) {
      try {
        await api.cancelLesson(credentials)
        dispatch("setScheduledLessons")
        dispatch("setClosedLessons")
      } catch (e) {
        console.error(e)
      }
    },
    async updateLesson({ dispatch }, payload) {
      await api.updateLesson(payload.data)
      dispatch(payload.updateTable)
    },
    async updateLessonMaterials({ dispatch }, payload) {
      const response = await api.updateLesson(transformToFormDataSerialize(payload.data))
      dispatch(payload.updateTable)
      return response
    },
    async removeFileFromLessonMaterials({ dispatch }, payload) {
      const response = await api.removeFile(payload.data)
      dispatch(payload.updateTable)
      return response
    },
    async rateLesson({ dispatch }, credentials) {
      await api.rateLesson(credentials)
      dispatch("setClosedLessons")
    },
    async getLesson({ commit }, payload) {
      const response = await api.getLesson(payload)
      commit("SET_LESSON", response.data)

      return response.data
    },
    clearLessons({ commit }) {
      commit("SET_SCHEDULED_LESSONS", [])
      commit("SET_CLOSED_LESSONS", [])
      commit("SET_GROUP_LESSONS", [])
    },
  },
  getters: {
    getClosedLessons: (state) => state.closedLessons,
    getSelected: (state) => state.selected,
    isLimitReached: (state) => {
      const { selected } = state
      const selectedSlots = Object.values(selected)
      return selectedSlots.length >= state.limit - state.scheduledLessonsCount
    },
  },
}
