<template>
  <div class="d-flex pa-2">
    <div class="file-item d-flex w-100 flex-grow-1" @click="downloadFile">
      <v-icon size="20" class="mr-2">$File</v-icon>
      <span class="file-name flex-grow-1" style="word-break: break-all">{{ name }}</span>
      <v-icon size="20" class="ml-2">$Download</v-icon>
    </div>
    <v-menu v-if="canDelete" bottom offset-y>
      <template #activator="{ on, attrs }">
        <v-icon v-bind="attrs" size="20" class="remove-file-btn ml-2" v-on="on">$Delete</v-icon>
      </template>
      <v-list>
        <v-list-item v-for="(item, i) in items" :key="i" @click="item.action()">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "BaseFileItem",
  props: {
    name: {
      type: String,
      default: "File",
    },
    url: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      userProfile: "profile/getProfile",
    }),
    items() {
      return [
        {
          title: this.$t("message.confirmDeletion"),
          action: () => {
            this.$emit("delete")
          },
        },
        {
          title: this.$t("message.void"),
          action: () => {
            console.log("cancel")
          },
        },
      ]
    },
    canDelete() {
      return this.userProfile.role === this.$props.type.toLowerCase()
    },
  },
  methods: {
    downloadFile() {
      const link = document.createElement("a")
      link.target = "_blank"
      link.href = this.url
      link.download = this.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
}
</script>

<style lang="scss" scoped>
.file-item {
  cursor: pointer;
  &:hover {
    background: #f7f9fb;
    .file-name {
      text-decoration: underline;
    }
  }
}
.remove-file-btn {
  cursor: pointer;
  &:hover {
    border-radius: 3px;
    background: #fad1d1;
  }
}
</style>
