import qs from "query-string"
const path = {
  getLesson: "/lessons/show",
  createLesson: "/lessons/create",
  cancelLesson: "/lessons/cancel",
  updateLesson: "/lessons/update",
  removeFile: "/lessons/remove_file",
  generateToken: "lessons/generate_token",
  scheduledLessons: "/lessons/list_scheduled",
  groupLesson: "/lessons/list_group",
  dummyScheduledLessons: "/lessons/dummy",
  closedLessons: "/lessons/list_finished",
  createRateLesson: "/ratings/create",
  updateRateLesson: "/ratings/update",
  createLessonInteraction: "lesson_interactions/create",
  reportAbsence: "lessons/report_absence",
}

export default {
  getScheduledLessons: async (axios) => {
    const res = await axios.get(path.scheduledLessons)

    return res
  },
  getGroupLessons: async (axios) => {
    const res = await axios.get(path.groupLesson)

    return res
  },
  getDummyScheduledLessons: async (axios) => {
    const res = await axios.get(path.dummyScheduledLessons)

    return res
  },

  getLesson: (axios) => async (payload) => {
    const res = await axios.get(`${path.getLesson}?${qs.stringify(payload)}`)

    return res
  },

  getClosedLessons: async (axios) => {
    const res = await axios.get(path.closedLessons)

    return res
  },

  createLesson: async (axios, lessonData) => {
    const res = await axios.post(path.createLesson, lessonData)
    return res
  },

  createLessonInteraction: async (axios, lessonData) => {
    const res = await axios.post(path.createLessonInteraction, lessonData)
    return res
  },

  cancelLesson: async (axios, credentials) => {
    const { lesson_id, cancellation_reason } = credentials
    const res = await axios.post(path.cancelLesson, {
      lesson_id,
      cancellation_reason,
    })

    return res
  },
  updateLesson: async (axios, updateData) => {
    const res = await axios.post(path.updateLesson, updateData)
    return res
  },
  removeLessonFile: async (axios, payload) => {
    const res = await axios.get(`${path.removeFile}?${qs.stringify(payload)}`)
    return res
  },
  updateLessonMaterials: async (axios, updateData) => {
    try {
      const res = await axios.post(path.updateLesson, updateData, {
        "Content-Type": "multipart/form-data",
      })
      return res
    } catch (err) {
      console.error(err)
      throw new Error("Failed to update lesson")
    }
  },

  rateLesson: async (axios, credentials) => {
    const { lesson_id, value, comment, mode } = credentials
    const ratePath = mode === "create" ? path.createRateLesson : path.updateRateLesson

    const res = await axios.post(ratePath, {
      lesson_id,
      value,
      comment,
    })

    return res
  },

  generateToken: (axios) => async (payload) => {
    const res = await axios.post(path.generateToken, payload)

    return res
  },

  reportAbsence: (axios) => async (payload) => {
    const res = await axios.post(path.reportAbsence, payload)

    return res
  },
}
