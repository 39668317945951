export default {
  methods: {
    getTimeZoneOffsetInPoland(date) {
      // We base on the fact that DST (daylight saving time) in Poland
      // starts at 2:00 AM on the last Sunday of March and ends at 3:00 AM on the last Sunday of October.
      // from last sunday of march to last sunday of october offset is 120 minutes, otherwise 60 minutes

      // get input date
      const inputDate = date ? new Date(date) : new Date()
      // get current year
      const inputYear = inputDate.getFullYear()
      // set timezone offset to default value (-60 minutes)
      let timezoneOffset = -60

      // set start date to last sunday of march
      const startDST = new Date(inputYear, 2, 31)
      while (startDST.getDay() !== 0) {
        startDST.setDate(startDST.getDate() - 1)
      }
      startDST.setHours(2, 0, 0, 0)

      // set end date to last sunday of october
      const endDST = new Date(inputYear, 9, 31)
      while (endDST.getDay() !== 0) {
        endDST.setDate(endDST.getDate() - 1)
      }
      endDST.setHours(3, 0, 0, 0)

      // check if current date is within DST period
      if (inputDate >= startDST && inputDate < endDST) {
        timezoneOffset = -120
      }

      return timezoneOffset
    },
    getDateNowInPoland() {
      const now = new Date()
      const polandTimezoneOffset = this.getTimeZoneOffsetInPoland()
      const timestamp = +now
      const userTimezoneOffset = now.getTimezoneOffset()
      if (userTimezoneOffset === polandTimezoneOffset) {
        return new Date()
      } else {
        return new Date(timestamp + (userTimezoneOffset - polandTimezoneOffset) * 60 * 1000)
      }
    },
  },
}
