import getDateNowInPoland from "@/mixins/getDateNowInPoland"

export default {
  mixins: [getDateNowInPoland],
  methods: {
    isJoinDisabled(item) {
      if (item.cancelled_at) return true
      const startDate = this.parseDate(item.scheduled_at)
      const endDate = this.parseDate(item.finished_at)
      const now = this.getDateNowInPoland()
      const nowWidthOffset = this.addMinutes(now, 15)
      const endWidthOffset = this.addMinutes(endDate, 15)
      return nowWidthOffset < startDate || now > endWidthOffset
    },
  },
}
