export default {
  data() {
    return {
      key: 1,
    }
  },
  methods: {
    keyPlus(key = "key") {
      this.$set(this, key, this[key] + 1)
    },
  },
}
